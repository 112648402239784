<template>
    <dropdown-wrapper dropdown-classes="tw-w-64">
        <template v-slot:menu-button>
            <MenuButton
                data-cy="compare-dropdown"
                class="tw-bg-allabolag-700 tw-text-white tw-text-sm tw-font-normal tw-px-2 tablet:tw-pl-1 tablet:tw-pr-3 tw-py-1 tw-mr-2 tw-rounded tw-leading-relaxed hover:tw-text-white hover:tw-no-underline hover:tw-bg-allabolag-600 focus:tw-bg-allabolag-600">
                  <span class="tw-flex tw-items-center">
                      &nbsp;<i class="tablet:tw-hidden far fa-exchange-alt fa-fw"></i>&nbsp;
                      <span class="tw-hidden tablet:tw-inline"> Jämför</span>
                  </span>
                <div class="tw-absolute tw-right-0 tw-top-0" v-if="companiesCurrentList.length > 0">
                    <div class="tw-bg-red-450 tw-rounded-full tw-w-4 tw-h-4 tw--mt-2 tw-flex tw-justify-center">
                        <span class="tw-text-xs">{{ companiesCurrentList.length }}</span>
                    </div>
                </div>
            </MenuButton>
        </template>

        <template v-slot:menu-items>
            <div class="tw-px-4" data-cy="compare-listofcompanies">
                <p v-if="companiesCurrentList.length === 0"
                   class="tw-text-sm tw-text-left tw-py-2">
                    Lägg till bolag för att jämföra med hjälp av knappar i sökresultaten eller inne på en
                    företagssida.
                </p>
                <div v-for="company of companiesCurrentList"
                     class="tw-flex tw-justify-between tw-w-full tw-py-2 tw-items-center">
                    <MenuItem v-slot="{ active }">
                        <a role="button" :class="[active && 'tw-text-allabolag-700 tw-underline']"
                           :href="'/' + company.orgnr">{{ company.name }}</a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                        <button
                            :class="[active && 'tw-text-allabolag-700']"
                            class="tw-px-2 tw-py-1 tw-rounded tw-border tw-border-white tw-transition tw-duration-200 hover:tw-border-gray-500 focus:tw-outline-black focus:tw-outline-2 focus:tw-outline-dotted focus:tw-outline-offset-2"
                            @click.prevent="removeFromCompare(company.orgnr)">
                            <i :class="[active && 'tw-underline']" class="fal fa-times tw-cursor-pointer"></i>
                        </button>
                    </MenuItem>
                </div>
                <MenuItem v-slot="{ active }">
                    <button @click="goToCompare()"
                            data-cy="compare-gotocompare"
                            :class="[active && 'tw-bg-allabolag-600']"
                            class="tw-block tw-btn tw-btn-allabolag-700 tw-w-full tw-mt-2">
                        <span class="tw-flex tw-items-center tw-justify-center">
                            &nbsp;<i class="far fa-exchange-alt fa-fw"></i>&nbsp;
                            <span>Jämför bolag</span>
                        </span>
                    </button>
                </MenuItem>
            </div>
        </template>
    </dropdown-wrapper>
</template>

<script>
import {MenuButton, MenuItem} from "@headlessui/vue";
import {EventBus} from "../common/eventBus.js";
import DropdownWrapper from "./DropdownWrapper.vue";

export default {
    components: {
        DropdownWrapper,
        MenuButton,
        MenuItem,
    },
    props: {
        companies: {
            default: []
        },
        maxCompanies: {
            default: 5
        }
    },
    data: function () {
        return {
            response: null,
            companiesCurrentList: typeof this.companies !== 'undefined' && this.companies !== null ? this.companies : [],
        }
    },
    methods: {
        async goToCompare() {
            if (this.companiesCurrentList.length > 0) {
                await this.getCompare();

                let url = '/jamfor/';

                if (this.response != null) {
                    for (let item of this.response) {
                        url += item.orgnr + '/';
                    }
                }

                url = url.substr(0, url.length - 1);

                window.location.href = url;
            } else {
                window.location.href = '/jamfor'
            }
        },
        getCompare() {
            let url = '/compare/get';
            return axios
                .get(url)
                .then(response => (this.compareResponse(response, url)))
                .catch(error => {
                    console.log(error.response);
                    console.error(error.stack);
                });

        },
        compareResponse(response, url) {
            return this.response = response.data;
        },
        removeCallHelper(event) {
            this.removeFromCompare(event.orgnr)
        },
        removeFromCompare(orgnr) {
            let newArray = [];
            for (let item of this.companiesCurrentList) {
                if (item.orgnr !== orgnr) {
                    newArray.push(item);
                } else {
                    dataLayer.push({
                        event: "removeFromCompare",
                    });
                }
            }
            this.companiesCurrentList = newArray;
            let url = '/compare/remove/' + orgnr.replace('-', '');
            axios
                .delete(url)
                .then(response => (this.removeCompareResponse(response, orgnr)))
                .catch(error => {
                    console.log(error.response);
                    console.error(error.stack);
                });
        },
        removeCompareResponse(response, orgnr) {
            EventBus.emit('removed-compare-company', {'orgnr': orgnr});

            this.$nextTick().then(() => {
                this.$forceUpdate();
            });
        },
        addToCompare(event) {
            if (this.companiesCurrentList.length < this.maxCompanies) {
                let url = '/compare/add/' + event.orgnr + /name/ + event.name;
                axios
                    .post(url)
                    .then(response => (this.compareAddResponse(response, event.orgnr, event.name)))
                    .catch(error => {
                        console.log(error.response);
                        console.error(error.stack);
                    });
            } else {
                EventBus.emit('max-companies-reached', {'orgnr': '' + event.orgnr});
            }
        },
        compareAddResponse(response, orgnr, name) {
            EventBus.emit('added-compare-company', {'orgnr': '' + orgnr, 'name': name});

            this.$nextTick().then(() => {
                this.$forceUpdate();
            });
        },
        addedCompareCompany(event) {
            if (typeof event.openBox !== 'undefined' && event.openBox !== false) {
                this.isOpen = true;
            }

            if (this.companiesCurrentList === null) {
                this.companiesCurrentList = [];
            }

            if (this.companiesCurrentList.length < this.maxCompanies) {
                this.companiesCurrentList.push({orgnr: event.orgnr, name: event.name});
                dataLayer.push({
                    event: "addToCompare",
                });
            } else {
                EventBus.emit('max-companies-reached');
            }
        },
        removedCompareCompany(event) {
            if (typeof this.companiesCurrentList !== 'undefined') {
                let newArray = [];
                for (let item of this.companiesCurrentList) {
                    if (item.orgnr !== event.orgnr) {
                        newArray.push(item);
                    }
                }
                this.companiesCurrentList = newArray;
            }
        },
        goToCompareIfMobile() {
            if (document.documentElement.clientWidth <= 768) {
                this.goToCompare();
            }
        },
    },
    created() {
        EventBus.on('add-to-compare-company-call', this.addToCompare);
        EventBus.on('added-compare-company', this.addedCompareCompany);
        EventBus.on('removed-compare-company', this.removedCompareCompany);
        EventBus.on('removed-compare-company-call', this.removeCallHelper);
    },
    mounted() {
        window.onpopstate = (event) => {
            this.getCompare();
        }
    }
}
</script>
