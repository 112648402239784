<template>
    <div class="tw-relative tw-z-1 tw-border-t tw-border-b tw-border-gray-300 tw-mb-8">
        <div class="tw-flex tw-justify-between tw-mb-0 tw-mt-8 tablet:tw-px-8">
            <div>
                <h2>Översiktsgrafer (tkr)</h2>
                <p class="tw-mt-4 tw-text-gray-600 tw-text-sm">i samarbete med Bonanza</p>
            </div>
            <div class="tw-flex tw-flex-col tw-w-32">
                <div class="tw-w-full tw-flex tw-justify-end tw-mb-3">
                    <img class="tw-text-2xl tw-w-3/5 tw-self-end" src="/Assets/img/bisgraf-logo.svg" alt="Bisgraf"/>
                </div>
                <select v-model="selectedYear"
                        class="tw-bg-white focus:tw-bg-white tw-border tw-border-gray-300 tw-w-4/5 tw-self-end tw-p-2 tw-m-0 tw-rounded
                        tw-max-w-full tw-bg-no-repeat tw-block tw-text-gray-500 tw-text-base tw-font-normal tw-appearance-none tw-leading-none">
                    <option v-for="option in options" :value="option.value">
                        {{ option.text }}
                    </option>
                </select>
            </div>
        </div>

        <div ref="bis"
             class="tw-overflow-x-hidden tablet:tw-overflow-y-hidden tw-w-full tw-flex tw-justify-center heightContainer">
            <div :id="bisgrafId" data-bisgraf=""
                 class="tw-overflow-hidden tw-py-8 height width height tablet:tw-px-8"></div>
        </div>
    </div>
</template>

<style scoped>
    select {
        font-family: "Nunito Sans", sans-serif;
        background-position: right .7em top 50%, 0 0;
    }

    .heightContainer {
        height: 840px;
    }

    @screen tablet {
        .heightContainer {
            height: 480px;
        }
    }

    .height {
        height: 840px;
    }

    @screen tablet {
        .height {
            height: 480px;
        }
    }

    .width {
        width: 100%;
    }

    @screen tablet {
        .width {
            min-width: 768px;
        }
    }
</style>

<script>
    export default {
        name: "BisgrafSelectYear",
        props: [
            'bisgrafId',
            'bisgrafValues',
            'initialYear',
            'availableYears',
        ],
        data: function () {
            return {
                selectedYear: this.initialYear,
                options: [],
                scale: 'same',
                windowWidth: 0,
                windowHeight: 0,
                clientX: 0,
                clientY: 0,
            }
        },
        mounted() {
            for (let year of this.availableYears) {
                this.options.push(
                    {
                        text: year,
                        value: year,
                    }
                )
            }

            window.addEventListener('resize', this.getWindowWidth);

            this.$nextTick(function () {
                this.getWindowWidth();
            });

            if (document.documentElement.clientWidth <= 768) {
                this.toggleScale();
                window.bisgraf.setValues({scale: this.scale, resetfirst: false});
            }

            this.resetOrInit();

            document.addEventListener('mousemove', this.getMouseXY, false);
        },
        watch: {
            selectedYear(newValue) {
                this.resetOrInit();
            },
        },
        methods: {
            getMouseXY(event) {
                this.clientX = event.clientX;
                this.clientY = event.clientY;
                let posPlusWidth = this.$refs.bis.getBoundingClientRect().left + this.$refs.bis.clientWidth - 200 - 40;

                window.jQuery('[data-bisgraf] div#bisgraf_grafdiv div#bisgraf_tooltip.bisgraf_tooltip').css(
                    'margin-left', this.clientX - 40 < posPlusWidth ? this.clientX - 40 : posPlusWidth
                );
                window.jQuery('[data-bisgraf] div#bisgraf_grafdiv div#bisgraf_tooltip.bisgraf_tooltip').css(
                    'margin-top', this.clientY + 10
                );
            },
            resetOrInit() {
                window.bisgraf.resetInit();
                window.bisgraf.setValues(this.bisgrafValues[this.selectedYear]);
            },
            toggleScale() {
                if (this.scale === 'same') {
                    this.scale = 'largest';
                } else {
                    this.scale = 'same';
                }
            },
            getWindowWidth(event) {
                this.windowWidth = document.documentElement.clientWidth;

                if (this.windowWidth > 768) {
                    if (this.scale === 'same') {
                        this.toggleScale();
                        window.bisgraf.setValues({scale: this.scale, resetfirst: false});
                    }
                } else {
                    if (this.scale === 'largest') {
                        this.toggleScale();
                        window.bisgraf.setValues({scale: this.scale, resetfirst: false});
                    }
                }
            },
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.getWindowWidth);
        }
    }
</script>
