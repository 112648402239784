<template>
    <div v-if="totalpages > 1">
        <button
            class="tw-transform tw-duration-200 tw-px-2 tw-py-1 tw-rounded tw-m-1"
            :class="[currentpage === page ? selectedItemClasses : itemClasses]"
            v-for="page in totalpages"
            @click="$emit('changepage', page)"
        >
            {{ page }}
        </button>
    </div>
</template>
<script>
export default {
    props: {
        currentpage: {
            type: Number,
            required: true
        },
        length: {
            type: Number,
            required: true
        },
        perpage: {
            type: Number,
            required: true
        },
        theme: {
            validator: function (value) {
                return ['green', 'blue'].indexOf(value) !== -1
            },
            default: 'green'
        }
    },
    data() {
        return {
            themecolors: {
                green: {
                    selected: 'tw-bg-green-900 tw-text-allabolag-300',
                    normal: 'tw-bg-allabolag-300 tw-text-green-900 hover:tw-bg-green-900 hover:tw-text-allabolag-300'
                },
                blue: {
                    selected: 'tw-bg-blue-450 tw-text-white',
                    normal: 'tw-bg-blue-350 tw-text-blue-500 hover:tw-bg-blue-450 hover:tw-text-white'
                }
            }
        }
    },
    computed: {
        totalpages: function() {
            return Math.ceil(this.length / this.perpage)
        },
        selectedItemClasses: function() {
            return this.themecolors[this.theme].selected;
        },
        itemClasses: function() {
            return this.themecolors[this.theme].normal;
        }
    }
}
</script>
