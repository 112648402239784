<template>
    <button
        @click="openModal"
        :class="[classes.length === 0 ? 'tw-relative hover:tw-underline': classes]"
    >
        <slot name="button"></slot>
    </button>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="closeModal">
            <div class="tw-fixed tw-inset-0 tw-z-10">
                <div class="tw-min-h-screen tw-px-4 tw-text-center">
                    <TransitionChild
                        as="template"
                        enter="tw-duration-300 tw-ease-out"
                        enter-from="tw-opacity-0"
                        enter-to="tw-opacity-100"
                        leave="tw-duration-200 tw-ease-in"
                        leave-from="tw-opacity-100"
                        leave-to="tw-opacity-0"
                    >
                        <DialogOverlay class="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50"/>
                    </TransitionChild>

                    <span class="tw-inline-block tw-h-screen tw-align-middle" aria-hidden="true">
                        &#8203;
                    </span>

                    <TransitionChild
                        as="template"
                        enter="tw-duration-300 tw-ease-out"
                        enter-from="tw-opacity-0 tw-scale-95"
                        enter-to="tw-opacity-100 tw-scale-100"
                        leave="tw-duration-200 tw-ease-in"
                        leave-from="tw-opacity-100 tw-scale-100"
                        leave-to="tw-opacity-0 tw-scale-95"
                    >
                        <div
                            class="tw-inline-block tw-max-w-md tw-p-6 tw-my-8 tw-text-left tw-align-middle tw-transition-all tw-transform tw-bg-white tw-shadow-xl tw-rounded-xl"
                        >
                            <DialogTitle
                                as="h3"
                                class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >
                                <slot name="header"></slot>
                            </DialogTitle>
                            <slot name="content"></slot>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script>
import {ref} from 'vue'
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
} from '@headlessui/vue'

export default {
    name: "ButtonModal",
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogOverlay,
        DialogTitle,
    },
    props: {
        class: {
            type: String,
            required: false,
            default: ''
        }
    },
    setup(props) {
        const isOpen = ref(false)

        const classes = props.class;

        return {
            isOpen,
            closeModal() {
                isOpen.value = false
            },
            openModal() {
                isOpen.value = true
            },
            classes
        }
    },
}
</script>
