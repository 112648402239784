<template>
    <div v-if="shouldShow">
        <h3 class="tw-font-bold tw-mb-1 tw-text-base tw-relative">
            Verklig huvudman <plus-indicator />
        </h3>
        <div class="tw-mb-4">
            <div v-if="!hasPlus">
                <span>Om bolaget har registrerat Verklig huvudman så visas den informationen här för våra Allabolag Pluskunder</span><br>
                <a :href="plusLink">Läs mer om Allabolag Plus</a>
            </div>
            <div v-else>
                <div v-if="hasData"
                     class="tw-grid tw-gap-x-3 tw-gap-y-5 tablet:tw-grid-cols-2 desktop:tw-grid-cols-3">
                    <div class="tw-flex tw-items-baseline" v-for="(beneficialOwner, index) in beneficialOwnersInternal" :key="index">
                        <div class="tw-text-sm">
                            <p><a :href="beneficialOwner.link">{{ beneficialOwner.name }}</a></p>
                            <p>
                                <span class="tw-font-bold">Bosatt, Medborgarskap: </span>
                                <span><template v-if="beneficialOwner.countryOfResidence">{{ beneficialOwner.countryOfResidence }}, </template>{{ beneficialOwner.nationality }}</span>
                            </p>
                            <p><span class="tw-font-bold">Andel: </span><span>{{ beneficialOwner.share }}</span></p>
                            <p v-if="beneficialOwner.companies.trim().length > 0"><span class="tw-font-bold">Genom: </span><span>{{ beneficialOwner.companies }}</span></p>
                        </div>
                    </div>
                </div>
                <div v-else>
                    Företaget har inga registrerade verkliga huvudmän.
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import PlusIndicator from "../common/PlusIndicator.vue";
const props = defineProps({
    hasPlus: {
        type: Boolean,
        required: true,
    },
    cfgShow: {
        type: Boolean,
        required: true,
    },
    cfgGet: {
        type: Boolean,
        required: true,
    },
    beneficialOwnerUrl: {
        type: String,
        required: true,
    },
    plusLink: {
        type: String,
        required: true,
    }
});

const beneficialOwners = ref([])
const shouldShow = ref(props.cfgShow)
const hasData = ref(false)
const regionNames = new Intl.DisplayNames(['sv'], {type: 'region'});

if (props.cfgGet && props.hasPlus) {
    axios.get(props.beneficialOwnerUrl).then((res) => {
        beneficialOwners.value = res.data.data
        shouldShow.value = res.data.shouldShow
        hasData.value = res.data.hasData
    })
}

const beneficialOwnersInternal = computed(() => {
    return beneficialOwners.value.map((beneficialOwner) => {
        beneficialOwner.nationality = regionNames.of(beneficialOwner.nationality.countryCode);

        if (beneficialOwner.countryOfResidence) {
            beneficialOwner.countryOfResidence = regionNames.of(beneficialOwner.countryOfResidence);
        }

        return beneficialOwner;
    })
})
</script>
