<template>
    <div>
        <div v-for="(item, index) in companies" class="tw-bg-white tw-border-b tw-border-gray-200 tw-p-3"
             :class="{ 'tw-border-t': index === 0 }">
            <div v-if="typeof item !== 'undefined' && (item.company.active || isHistorical)"
                 class="tablet:tw-flex tablet:tw-items-center">
                <h3 class="tablet:tw-w-1/4" :class="{'tw-text-allabolag-600': item.company.active}">
                    <a v-if="item.company.active" :href="'/' + item.company.orgnr">
                        {{item.company.jurnamn}}
                    </a>
                    <a v-else :href="'/' + item.company.orgnr">
                        {{item.company.jurnamn}} {{item.company.active? '(Inaktivt bolag)' : ''}}
                    </a>
                </h3>
                <p class="tw-text-sm tablet:tw-ml-4">
                    <span v-for="(assignment, index) in item.assignments">
                        {{assignment.description}}<span v-if="index !== item.assignments.length - 1"
                                                        class="tw-hidden tablet:tw-inline">,&nbsp;</span>
                        <span class="tablet:tw-hidden"><br/></span>
                    </span>
                    sedan {{dateFrom(item.datum_from, item.datum_to)}} {{item.datum_to !== null? 'till ' + item.datum_to : ''}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ExecutivePersonItems",

        props: [
            'companies',
            'isHistorical',
        ],
        methods: {
            dateFrom: function(from, to) {
                var unknown_dates = ["2003-10-31", "2003-11-01"];
                if (!to) {
                    if(unknown_dates.includes(from)){
                        return "okänt";
                    }
                    return from;
                }

                var date_from = new Date(from);
                var date_to = new Date(to);

                if (date_from.getTime() >= date_to.getTime() || unknown_dates.includes(from)) {
                    return "okänt";
                } else {
                    return from;
                }
            },

        }
    }
</script>
