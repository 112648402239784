<template>
    <RadioGroup v-model="plan" name="productType">
        <RadioGroupOption v-slot="{ checked }" value="allabolagPlusYearly">
            <div class="tw-flex tw-items-center tw-justify-between tw-h-16 tw-p-4 tw-border tw-border-gray-300 tw-rounded-md tw-mb-3 tw-relative"
                 :class="checked ? 'tw-bg-allabolag-300' : ''"
            >
                <span class="tw-absolute tw-top-0 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-text-xs tw-text-white tw-p-1 tw-bg-green-800 tw-rounded-md phablet:tw-hidden">25% rabatt</span>
                <div class="tw-flex tw-items-center tw-gap-x-2 tablet:tw-gap-x-4">
                    <nds-radio :selected="checked" selectedClasses="tw-ring-allabolag-300 tw-bg-green-800 tw-border-green-800"/>
                    <span class="tw-text-sm tw-text-gray-700">
                        Betalas årsvis
                    </span>
                    <span class="tw-text-xs tw-text-white tw-p-1 tw-bg-green-800 tw-rounded-md tw-hidden phablet:tw-block">25% rabatt</span>
                </div>
                <div class="tw-flex tw-flex-col tablet:tw-flex-row tw-gap-x-2 tablet:tw-gap-x-4">
                    <span class="tw-text-purple-500 tw-font-semibold">799 kr/år</span>
                    <span class="tw-text-sm tw-text-gray-700">exkl. moms</span>
                </div>
            </div>
        </RadioGroupOption>
        <RadioGroupOption v-slot="{ checked }" value="allabolagPlus">
            <div class="tw-flex tw-items-center tw-justify-between tw-h-16 tw-p-4 tw-border tw-border-gray-300 tw-rounded-md tw-mb-3"
                 :class="checked ? 'tw-bg-allabolag-300' : ''"
            >
                <div class="tw-flex tw-items-center tw-gap-x-2 tablet:tw-gap-x-4">
                    <nds-radio :selected="checked" selectedClasses="tw-ring-allabolag-300"/>
                    <div class="tw-flex tw-flex-col">
                        <span class="tw-text-sm tw-text-gray-700">
                            Betalas månadsvis
                        </span>
                        <span class="tw-text-xs tw-font-semibold tw-text-gray-400">
                            Ingen bindningstid
                        </span>
                    </div>
                </div>
                <div class="tw-flex tw-flex-col tablet:tw-flex-row tw-gap-x-2 tablet:tw-gap-x-4">
                    <span class="tw-text-purple-500 tw-font-semibold">89 kr/mån</span>
                    <span class="tw-text-sm tw-text-gray-700">exkl. moms</span>
                </div>
            </div>
        </RadioGroupOption>
    </RadioGroup>
</template>
<script setup>
    import { ref } from 'vue'
    import {
        RadioGroup,
        RadioGroupLabel,
        RadioGroupOption,
    } from '@headlessui/vue'

    const plan = ref('allabolagPlusYearly')
</script>
