<template>
    <div class="tw-mb-8 tw-border-b tw-border-gray-100">
        <h1>Befattningshavare</h1>
        <div class="tw-flex tw-flex-col tw-mb-6">
            <div v-if="totalNumberOfExecutives > 0" class="tw-w-full">
                <p>Det sitter totalt {{totalNumberOfExecutives}} personer i styrelsen. </p>
            </div>

            <div v-if="hasExecutivesExcludingCompanies"
                 class="tw-mt-10 tablet:tw-flex tablet:tw-w-full tablet:tw-flex-wrap">
                <div class="tw-w-full tw-mb-4 tablet:tw-mb-0 tablet:tw-w-1/2 tw-flex tw-flex-col">
                    <div class="tw-flex tablet:tw-flex-row tablet:tw-px-10 tablet:tw-pt-10">
                        <div class="tw-w-40 tw-mb-3 tablet:tw-mb-0 tablet:tw-h-40">
                            <VueChartist :data="menWomanRatio" :options="options" type="Pie"></VueChartist>
                        </div>
                        <div class="tw-flex tw-flex-col tw-justify-end tw-w-1/2 tw-ml-4">
                            <div class="tw-mb-4 tw-flex">
                                <span class="tw-p-2 tw-bg-allabolag-500 tw-mr-2 tw-h-0"></span>
                                <p class="tw-text-xs"><span>{{males}} Män</span></p>
                            </div>
                            <div class="tw-mb-4 tw-flex">
                                <span class="tw-p-2 tw-bg-blue-450 tw-mr-2 tw-h-0"></span>
                                <p class="tw-text-xs"><span>{{females}} Kvinnor</span></p>
                            </div>
                            <div class="tw-mb-4 tw-flex">
                                <span class="tw-p-2 tw-bg-gray-200 tw-mr-2 tw-h-0"></span>
                                <p class="tw-text-xs"><span>{{unknown}} Utlandsbosatta</span></p>
                            </div>
                        </div>
                    </div>
                    <p class="tablet:tw-pb-10">{{maleFemaleRatio}}</p>
                </div>
                <div class="tw-flex tw-w-full tw-flex-col tablet:tw-w-1/2 tablet:tw-px-10 tablet:tw-py-10">
                    <h4 class="tw-flex tw-items-end text-8xl tw-leading-none tablet:tw-h-40">{{averageAgeValue}}</h4>
                    <p>Medelålder på styrelse.</p>
                </div>
            </div>
        </div>

        <div class="tw-hidden tablet:tw-flex tablet:tw-justify-between tw-font-bold tw-text-gray-700">
            <div class="tw-flex tw-w-1/4 tw-pl-5">
                <div>
                    Namn
                </div>
            </div>
            <div class="tw-flex tw-w-1/4 tw-pl-5">
                <div>
                    Roll
                </div>
            </div>
            <div class="tw-flex tw-w-1/4 tw-pl-5">
                <div>
                    Aktiva uppdrag
                </div>
            </div>
            <div class="tw-flex tw-w-1/4 tw-pl-5">
                <div class="tw-relative tw-flex">
                    Tidigare uppdrag
                    <div class="tw-absolute tw--mt-3 tw-right-0 tw-top-0">
                        <plus-indicator></plus-indicator>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="mainExecutives.executives.length > 0"
             class="tw-flex tw-w-full tw-flex-col">
            <div class="tw-bg-gray-50 py-4">
                <h3 class="tw-pl-2 tw-py-2 tw-border-b tw-border-t tw-border-gray-200">
                    {{ mainExecutives.mainHeaderText }} (<span v-if="mainExecutives.cut">fler än </span>{{ mainExecutives.mainHeaderCount }})
                </h3>
            </div>

            <template v-for="(activeExecutive, j) in paginatedRecordsForMainExecutives">
                <executive-person
                    v-if="typeof activeExecutive[0] !== 'undefined'"
                    v-bind:key="'main' + activeExecutive[0].objectIdMd5"

                    :has-plus="hasPlus"
                    :active-missions-count="activeExecutivesCount[activeExecutive[0].objectIdMd5]"
                    :historical-mission-count="historicalExecutivesCount[activeExecutive[0].objectIdMd5]"
                    :internal-id="activeExecutive[0].objectIdMd5"
                    :current-board-member="currentBoardMember(mainExecutives.executivesCurrentCompany, activeExecutive[0].objectIdMd5)"
                    :companies="companiesForExecutive(mainExecutives.executives, activeExecutive[0].objectIdMd5)"
                    :companies-historical="mainExecutives.executivesHistorical"
                    :birth-year="activeExecutive[0].birthYear"
                    :link-to="activeExecutive[0].linkTo"
                    :link-to-connection="activeExecutive[0].linkToConnection"
                    :index="j"
                    :index-last="mainExecutives.executives.length"
                >
                </executive-person>
            </template>
            <paginator
                class="tw-my-2"
                :currentpage="mainExecutivesCurrentPage"
                :length="mainExecutives.executives.length"
                :perpage="perpage"
                @changepage="mainExecutivesCurrentPage = $event"
            ></paginator>
        </div>

        <div v-if="boardAndSupervisor.executives.length > 0"
             class="tw-flex tw-w-full tw-flex-col">
            <div class="tw-bg-gray-50 py-4">
                <h3 class="tw-pl-2 tw-py-2 tw-border-b tw-border-t tw-border-gray-200">
                    {{ boardAndSupervisor.mainHeaderText }} (<span v-if="boardAndSupervisor.cut">fler än </span>{{ boardAndSupervisor.mainHeaderCount }})
                </h3>
            </div>

            <template v-for="(activeExecutive, j) in paginatedRecordsForBoardAndSupervisor">
            <executive-person
                v-if="typeof activeExecutive[0] !== 'undefined'"
                v-bind:key="'main' + activeExecutive[0].objectIdMd5"

                :has-plus="hasPlus"
                :active-missions-count="activeExecutivesCount[activeExecutive[0].objectIdMd5]"
                :historical-mission-count="historicalExecutivesCount[activeExecutive[0].objectIdMd5]"
                :internal-id="activeExecutive[0].objectIdMd5"
                :current-board-member="currentBoardMember(boardAndSupervisor.executivesCurrentCompany, activeExecutive[0].objectIdMd5)"
                :companies="companiesForExecutive(boardAndSupervisor.executives, activeExecutive[0].objectIdMd5)"
                :companies-historical="boardAndSupervisor.executivesHistorical"
                :birth-year="activeExecutive[0].birthYear"
                :link-to="activeExecutive[0].linkTo"
                :link-to-connection="activeExecutive[0].linkToConnection"
                :index="j"
                :index-last="boardAndSupervisor.executives.length"
            >
            </executive-person>
            </template>
            <paginator
                class="tw-my-2"
                :currentpage="boardAndSupervisorCurrentPage"
                :length="boardAndSupervisor.executives.length"
                :perpage="perpage"
                @changepage="boardAndSupervisorCurrentPage = $event"
            ></paginator>
        </div>

        <div v-if="supervisionExecutives.executives.length > 0"
             class="tw-flex tw-w-full tw-flex-col">
            <div class="tw-bg-gray-50 py-4">
                <h3 class="tw-pl-2 tw-py-2 tw-border-b tw-border-t tw-border-gray-200">
                    {{ supervisionExecutives.mainHeaderText }} (<span v-if="supervisionExecutives.cut">fler än </span>{{ supervisionExecutives.mainHeaderCount }})
                </h3>
            </div>

            <template v-for="(activeExecutive, j) in paginatedRecordsForSupervisionExecutives">
            <executive-person
                v-if="typeof activeExecutive[0] !== 'undefined'"
                v-bind:key="'main' + activeExecutive[0].objectIdMd5"

                :has-plus="hasPlus"
                :active-missions-count="activeExecutivesCount[activeExecutive[0].objectIdMd5]"
                :historical-mission-count="historicalExecutivesCount[activeExecutive[0].objectIdMd5]"
                :internal-id="activeExecutive[0].objectIdMd5"
                :current-board-member="currentBoardMember(supervisionExecutives.executivesCurrentCompany, activeExecutive[0].objectIdMd5)"
                :companies="companiesForExecutive(supervisionExecutives.executives, activeExecutive[0].objectIdMd5)"
                :companies-historical="supervisionExecutives.executivesHistorical"
                :birth-year="activeExecutive[0].birthYear"
                :link-to="activeExecutive[0].linkTo"
                :link-to-connection="activeExecutive[0].linkToConnection"
                :index="j"
                :index-last="supervisionExecutives.executives.length"
            >
            </executive-person>
            </template>
            <paginator
                class="tw-my-2"
                :currentpage="supervisionExecutivesCurrentPage"
                :length="supervisionExecutives.executives.length"
                :perpage="perpage"
                @changepage="supervisionExecutivesCurrentPage = $event"
            ></paginator>
        </div>

        <div v-if="otherExecutives.executives.length > 0"
             class="tw-flex tw-w-full tw-flex-col">
            <div class="tw-bg-gray-50 py-4">
                <h3 class="tw-pl-2 tw-py-2 tw-border-b tw-border-t tw-border-gray-200">
                    {{ otherExecutives.mainHeaderText }} (<span v-if="otherExecutives.cut">fler än </span>{{ otherExecutives.mainHeaderCount }})
                </h3>
            </div>

            <template v-for="(activeExecutive, j) in paginatedRecordsForOtherExecutives">
            <executive-person
                v-if="typeof activeExecutive[0] !== 'undefined'"
                v-bind:key="'main' + activeExecutive[0].objectIdMd5"

                :has-plus="hasPlus"
                :active-missions-count="activeExecutivesCount[activeExecutive[0].objectIdMd5]"
                :historical-mission-count="historicalExecutivesCount[activeExecutive[0].objectIdMd5]"
                :internal-id="activeExecutive[0].objectIdMd5"
                :current-board-member="currentBoardMember(otherExecutives.executivesCurrentCompany, activeExecutive[0].objectIdMd5)"
                :companies="companiesForExecutive(otherExecutives.executives, activeExecutive[0].objectIdMd5)"
                :companies-historical="otherExecutives.executivesHistorical"
                :birth-year="activeExecutive[0].birthYear"
                :link-to="activeExecutive[0].linkTo"
                :link-to-connection="activeExecutive[0].linkToConnection"
                :index="j"
                :index-last="otherExecutives.executives.length"
            >
            </executive-person>
            </template>
            <paginator
                class="tw-my-2"
                :currentpage="otherExecutivesCurrentPage"
                :length="otherExecutives.executives.length"
                :perpage="perpage"
                @changepage="otherExecutivesCurrentPage = $event"
            ></paginator>
        </div>
    </div>
</template>

<style scoped>
    .text-8xl {
        font-size: 8rem;
    }
</style>

<script>
    import ExecutivePerson from "./ExecutivePerson.vue";
    import Paginator from "../Paginator.vue";
    import VueChartist from "../VueChartist.vue";

    export default {
        name: "Executives",

        components: {
            Paginator,
            ExecutivePerson,
            VueChartist,
        },
        props: [
            'males',
            'unknown',
            'females',
            'maleFemaleRatio',
            'averageAgeValue',
            'hasPlus',
            'activeExecutivesCount',
            'historicalExecutivesCount',
            'mainActiveExecutives',
            'mainHistoricalExecutives',
            'boardAndSupervisionActiveExecutives',
            'boardAndSupervisionHistoricalExecutives',
            'othersActiveExecutives',
            'othersHistoricalExecutives',
            'supervisionActiveExecutives',
            'supervisionHistoricalExecutives',
            'executivesBoard',
            'supervisionExecutivesCut',
            'othersExecutivesCut',
            'boardAndSupervisionExecutivesCut',
            'mainExecutivesCut'
        ],
        data: function () {
            return {
                perpage: 10,
                currentPage: 1,
                mainExecutivesCurrentPage: 1,
                boardAndSupervisorCurrentPage: 1,
                supervisionExecutivesCurrentPage: 1,
                otherExecutivesCurrentPage: 1,
                totalNumberOfExecutives: this.males + this.unknown + this.females,
                menWomanRatio: {
                    labels: ['Man', 'Utlandsbosatta', 'Kvinnor'],
                    series: [
                        this.males,
                        this.unknown,
                        this.females,
                    ]
                },
                options: {
                    donut: true,
                    donutWidth: 30,
                    startAngle: 0,
                    total: this.males + this.females + this.unknown,
                    showLabel: false
                },
                expanded: false,
                mainExecutives: {
                    executivesCurrentCompany: this.executivesBoard.filter(
                        (boardItem) => {
                            if (typeof boardItem !== 'undefined') {
                                let hasAssignment = false;
                                if(typeof boardItem.group.EB !== 'undefined'){
                                    hasAssignment = true;
                                }
                                return hasAssignment;
                            } else {
                                return false;
                            }
                        }
                    ),
                    executives: this.mainActiveExecutives,
                    executivesHistorical: this.mainHistoricalExecutives,
                    mainHeaderText: 'Exekutiva befattningar',
                    mainHeaderCount: this.mainActiveExecutives.length,
                    cut: this.mainExecutivesCut,
                },
                boardAndSupervisor: {
                    executivesCurrentCompany: this.executivesBoard.filter(
                        (boardItem) => {
                            if (typeof boardItem !== 'undefined') {
                                let hasAssignment = false;
                                if(typeof boardItem.group.SB !== 'undefined'){
                                    hasAssignment = true;
                                }
                                return hasAssignment;
                            } else {
                                return false;
                            }
                        }
                    ),
                    executives: this.boardAndSupervisionActiveExecutives,
                    executivesHistorical: this.boardAndSupervisionHistoricalExecutives,
                    mainHeaderText: 'Styrelse/övriga bevakande befattningar',
                    mainHeaderCount: this.boardAndSupervisionActiveExecutives.length,
                    cut: this.boardAndSupervisionExecutivesCut,
                },
                supervisionExecutives: {
                    executivesCurrentCompany: this.executivesBoard.filter(
                        (boardItem) => {
                            if (typeof boardItem !== 'undefined') {
                                let hasAssignment = false;
                                if(typeof boardItem.group.TB !== 'undefined'){
                                    hasAssignment = true;
                                }
                                return hasAssignment;
                            } else {
                                return false;
                            }
                        }
                    ),
                    executives: this.supervisionActiveExecutives,
                    executivesHistorical: this.supervisionHistoricalExecutives,
                    mainHeaderText: 'Tillsynsbefattningar',
                    mainHeaderCount: this.supervisionActiveExecutives.length,
                    cut: this.supervisionExecutivesCut,
                },
                otherExecutives: {
                    executivesCurrentCompany: this.executivesBoard.filter(
                        (boardItem) => {
                            if (typeof boardItem !== 'undefined') {
                                let hasAssignment = false;
                                if(typeof boardItem.group.OB !== 'undefined'){
                                    hasAssignment = true;
                                }
                                return hasAssignment;
                            } else {
                                return false;
                            }
                        }
                    ),
                    executives: this.othersActiveExecutives,
                    executivesHistorical: this.othersHistoricalExecutives,
                    mainHeaderText: 'Övriga befattningar',
                    mainHeaderCount: this.othersActiveExecutives.length,
                    cut: this.othersExecutivesCut,
                }
            }
        },
        computed: {
            hasExecutivesExcludingCompanies() {
                return this.males > 0 || this.females > 0 || this.unknown > 0;
            },
            paginatedRecordsForMainExecutives() {
                return this.mainExecutives.executives.slice(this.perpage * (this.mainExecutivesCurrentPage - 1), this.perpage * this.mainExecutivesCurrentPage);
            },
            paginatedRecordsForBoardAndSupervisor() {
                return this.boardAndSupervisor.executives.slice(this.perpage * (this.boardAndSupervisorCurrentPage - 1), this.perpage * this.boardAndSupervisorCurrentPage);
            },
            paginatedRecordsForSupervisionExecutives() {
                return this.supervisionExecutives.executives.slice(this.perpage * (this.supervisionExecutivesCurrentPage - 1), this.perpage * this.supervisionExecutivesCurrentPage);
            },
            paginatedRecordsForOtherExecutives() {
                return this.otherExecutives.executives.slice(this.perpage * (this.otherExecutivesCurrentPage - 1), this.perpage * this.otherExecutivesCurrentPage);
            },
        },
        methods: {
            currentBoardMember(executivesCurrentBoard, id){
                return executivesCurrentBoard.find(
                    (member) => {
                        if (typeof member !== 'undefined') {
                            return member.id === id && member.datum_to === null;
                        } else {
                            return false;
                        }
                    }
                );
            },
            companiesForExecutive(executive, id){
                return executive.filter(
                    (members) => {
                        if (typeof members[0] !== 'undefined') {
                            return members[0].objectIdMd5 === id;
                        } else {
                            return false
                        }
                    }
                );
            }
        },
    }
</script>
