<template>
    <Menu as="div" class="tw-relative tw-inline-block tw-text-left">
        <slot name="menu-button"></slot>
        <transition
            enter-active-class="tw-transition tw-duration-100 tw-ease-out"
            enter-from-class="tw-transform tw-scale-95 tw-opacity-0"
            enter-to-class="tw-transform tw-scale-100 tw-opacity-100"
            leave-active-class="tw-transition tw-duration-75 tw-ease-in"
            leave-from-class="tw-transform tw-scale-100 tw-opacity-100"
            leave-to-class="tw-transform tw-scale-95 tw-opacity-0">
            <MenuItems class="tw-bg-white tw-shadow tw-absolute tw-mt-2 tw-py-2 tw-right-0 dropdown-menu"
                       :class="dropdownClasses">
                <slot name="menu-items"></slot>
            </MenuItems>
        </transition>
    </Menu>
</template>

<style scoped>
.dropdown-menu:after {
    content: "";
    position: absolute;
    right: 1.25rem;
    top: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #ffffff transparent;
    z-index: 9998;
}
</style>
<script>
import {Menu, MenuItems} from "@headlessui/vue";

export default {
    components: {
        Menu,
        MenuItems,
    },
    props: {
        dropdownClasses: String,
    }
}
</script>
