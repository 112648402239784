<template>
    <div :class="outerContainerClass">
        <Combobox v-model="selected">
            <div
                class="tw-relative tw-mt-1"
                :class="containerClass"
            >
                <div class="tw-relative tw-h-full tw-flex tw-items-center">
                    <i class="far fa-search tw-absolute tw-ml-3"></i>
                    <ComboboxInput
                        class="tw-border-2 tw-rounded-md focus:tw-border-green-800 placeholder:tw-text-gray-500 tw-w-full tw-pl-10"
                        :name="name"
                        :placeholder="placeholder"
                        :displayValue="keepSearchInput
                            ? () => ''
                            : (useName
                                ? (company) => company.jurnamn
                                : (company) => company.orgnr
                            )"
                        @change="searchinput = $event.target.value"
                    />
                </div>
                <div
                    v-if="message"
                    class="tw-p-2 tw-mt-2 tw-border tw-border-blue-500 tw-rounded"
                >
                    <i class="fal fa-info-circle"></i> {{ message }}
                </div>
                <TransitionRoot
                    v-if="searchinput !== ''"
                    leave="tw-transition tw-ease-in tw-duration-100"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <ComboboxOptions
                        class="tw-absolute tw-bg-white tw-py-1 tw-rounded-md tw-border-2 tw-border-green-800 tw-z-10 tw-w-full tw-mt-1.5"
                    >
                        <div
                            v-if="companyList.length === 0 && isSearching && searchDone"
                            class="tw-cursor-default tw-select-none tw-relative tw-py-2 tw-px-4 tw-text-gray-700"
                        >
                            Inga träffar matchade din sökning
                        </div>
                        <div class="tw-cursor-default tw-select-none tw-relative tw-text-gray-700 tw-h-36 tw-flex tw-justify-center tw-items-center tw-p-4" v-if="isSearching && !searchDone">
                            <spinner width="96px" height="96px"></spinner>
                        </div>
                        <ComboboxOption
                            v-for="company in companyList"
                            as="template"
                            :key="company.orgnr"
                            :value="company"
                            :disabled="company.disabled"
                            v-slot="{ selected, active }"
                        >
                            <li
                                class="tw-cursor-default tw-select-none tw-relative tw-py-2 tw-px-4"
                                :class="{ 'tw-bg-gray-50': active, 'tw-flex tw-justify-between': showOrgNr, 'tw-text-gray-900': !company.disabled, 'tw-text-gray-500': company.disabled }"
                            >
                                <span
                                    :class="{'tw-block tw-truncate': !showOrgNr, 'tw-font-medium': !showOrgNr && selected, 'tw-font-normal': !showOrgNr && !selected }"
                                >
                                  {{ company.jurnamn }}
                                </span>
                                <span
                                    v-if="showOrgNr"
                                >
                                  {{ company.orgnr }}
                                </span>
                                <span v-if="company.disabled" class="tw-font-normal tw-italic">
                                    Värdering ej tillgänglig
                                </span>
                            </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </TransitionRoot>
            </div>
        </Combobox>
    </div>
</template>
<script setup>
import { ref, watch } from 'vue'
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
} from '@headlessui/vue'
import Spinner from "./common/Spinner.vue";

const props = defineProps({
    name: {
        type: String,
        required: false
    },
    placeholder: {
        type: String,
        required: false
    },
    showOrgNr: {
        type: Boolean,
        default: false,
        required: false
    },
    onClickOption:{
        type: Function,
        default: (c) => {},
        required: false,
    },
    keepSearchInput:{
        type: Boolean,
        default: false,
        required: false
    },
    useName:{
        type: Boolean,
        default: false,
        required: false
    },
    message:{
        type: String,
        required: false
    },
    outerContainerClass:{
        type: String,
        defautl: null,
        required: false
    },
    containerClass:{
        type: String,
        deault: null,
        required: false
    },
    disableitems: {
        type: Function,
        default: async (items) => { return items; },
        required: false
    }
})

const emit = defineEmits(['new-search'])

const searchinput = ref('')
let debouncetimer = null

const isSearching = ref(false)
const searchDone = ref(false)
const selected = ref({ jurnamn: '', orgnr: ''})

const setSelected = function(selectedCompany) {
    searchinput.value = selectedCompany.orgnr
    companyList.value = []
    isSearching.value = false
    searchDone.value = false
}

const companyList = ref([])

const executeSearch = async function() {
    const res = await axios.get('/what/' + searchinput.value + '/xb/AB')
    let values = res.data.hitlistVue.slice(0, 10);
    companyList.value = await props.disableitems(values)
    searchDone.value = true
}

watch(searchinput, () => {
    clearTimeout(debouncetimer)
    emit('new-search')
    if (searchinput.value.trim().length === 0) {
        isSearching.value = false
        searchDone.value = true
        companyList.value = []
        return
    }
    isSearching.value = true
    searchDone.value = false
    companyList.value = []

    debouncetimer = setTimeout(executeSearch, 300)
})

watch(selected, () => {
    if (!props.onClickOption) {
        return
    }

    props.onClickOption(selected.value)
})
</script>
