<template>
    <div>
        <div class="tw-pb-2">
            <button v-for="year in years"
                    class="tw-inline-block tw-py-1 tw-px-2 tw-mx-1 tw-font-sm hover:tw-bg-purple-500 hover:tw-text-white tw-rounded"
                    :class="{'tw-bg-purple-500 tw-text-white': selectedYear === year}"
                @click="selectYear(year)">
                {{ year }}
            </button>
        </div>
        <div>
            <div v-for="event in eventsForPage(eventsForCurrentYear, currentPage)">
                <company-event :event="event"></company-event>
            </div>
            <div v-if="eventsForCurrentYear.length > perPage">
                <button @click="currentPage = (1+page)"
                        v-for="(tmp,page) in availablePages"
                        class="tw-rounded tw-px-2 tw-py-1 tw-mr-1 hover:tw-bg-purple-500 hover:tw-text-white"
                        :class="{'tw-bg-purple-500 tw-text-white': currentPage === (1 + page)}">{{ 1 + page }}</button>
            </div>
        </div>
        <div v-if="shouldShowPlus" class="tw-my-8">
            <span class="tw-text-2xl tw-block tw-pb-8 tw-font-semibold">För att se tidigare bolagshändelser behöver du skaffa Allabolag Plus</span>
            <div class="tw-bg-blue-300 tw-block tablet:tw-flex tw-justify-between tw-items-center tw-px-8 tw-py-4">
                <div>
                    <span class="tw-text-xl tw-font-semibold tw-block">Allabolag Plus</span>
                    <span class="tw-text-xl"><span class="tw-text-4xl tw-text-purple-500 tw-font-bold">89 kr</span> exkl. moms / mån</span>
                </div>
                <div class="tw-mt-4 tw-mb-2 tablet:tw-mt-0 tablet:tw-mb-0">
                    <slot name="plusbutton">
                        <a href="/plus" class="tw-block tw-btn tw-btn-purple tw-py-2 tw-px-4" id="cta-plus-company-events">Skaffa Allabolag Plus</a>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import CompanyEvent from './company-events/CompanyEvent.vue'

    export default {
        props: ['years', 'events', 'eventcount'],
        components: { CompanyEvent },
        data: function() {
            return {
                selectedYear: this.years.slice(-1)[0],
                perPage: 6,
                currentPage: 1,
            }
        },
        methods: {
            eventsForPage(events, page = 1) {
                return events.slice((page - 1) * this.perPage, page * this.perPage)
            },
            selectYear: function(year) {
                this.currentPage = 1;
                this.selectedYear = year;
            }
        },
        computed: {
            eventsForCurrentYear: function() {
                if (Object.keys(this.events).includes(this.selectedYear.toString())) {
                    return this.events[this.selectedYear];
                } else {
                    return [];
                }
            },
            shouldShowPlus: function() {
                var cnt = 0;
                var tmpevents = this.events;
                this.years.forEach(function(year) {
                    if (tmpevents[year] !== undefined) {
                        cnt += tmpevents[year].length;
                    }
                });

                return cnt < this.eventcount;
            },
            availablePages: function() {
                return new Array(Math.ceil(this.eventsForCurrentYear.length / this.perPage));
            }
        }
    }
</script>
