<template>
    <div class="tw-border-t tw-border-gray-200 tw-pb-4">
        <header class="tw-flex tw-justify-between tw-my-3 tw-text-lg">
            <div class="tw-text-purple-500 tw-font-bold">{{ event.eventName }}</div>
            <div class="tw-text-gray-500">{{ event.sourceDate }}</div>
        </header>
        <div class="tw-text-sm">
            <div v-if="['BOKDATA', 'BOKDATA2', 'CFARDATA'].includes(event.sourceTable)">
                <div class="tw-flex">
                    <div class="tw-flex-1">
                        <p>Ny:</p>
                        <ul v-if="event.data.New">
                            <template v-for="item in Object.keys(event.data.New)">
                                <li>{{ item }}: {{ event.data.New[item].replace(/¤/g, ' ') }}</li>
                            </template>
                        </ul>
                    </div>
                    <div class="tw-flex-1">
                        <p>Gammal:</p>
                        <ul v-if="event.data.Old">
                            <template v-for="item in Object.keys(event.data.Old)">
                                <li>{{ item }}: {{ event.data.Old[item].replace(/¤/g, ' ') }}</li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-else-if="['FUNKREG'].includes(event.sourceTable)">
                <div class="tw-flex">
                    <div class="tw-flex-1">
                        <p>Nytillträdda:</p>
                        <ul v-if="event.added && event.added.length > 0">
                            <li v-for="item in event.added" :key="item.id">{{ item.name }}, {{ item.assignment }} {{ item.role }}</li>
                        </ul>
                    </div>
                    <div class="tw-flex-1">
                        <p>Avregistrerade:</p>
                        <ul v-if="event.removed && event.removed.length > 0">
                            <li v-for="item in event.removed" :key="item.id">{{ item.name }}, {{ item.assignment }} {{ item.role }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-else>
                <div>
                    <span v-html="descriptionToHtml"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['event'],
        computed: {
            descriptionToHtml() {
                return this.event.description.replace(/\n/g, '<br>').replace(/¤/g, ' ');
            }
        }
    }
</script>
