<template>
    <div v-if="currentBoardMember" :class="{
        'tw-border-b-0': isLast,
        'tw-border-b': !isLast,
        'tw-bg-gray-50 tw-border-gray-50': expanded,
        'tw-bg-white tw-border-gray-100': !expanded,
        }"
    >
        <div
            class="tw-px-2 tw-py-3 tablet:tw-px-2 tablet:tw-py-3 tablet:tw-flex tablet:tw-justify-between tablet:tw-items-center hover:tw-bg-gray-100"
            @click="expanded = !expanded"
            :class="{'tw-bg-gray-100': expanded}">
            <div class="tw-flex tw-justify-between tw-items-center tablet:tw-w-1/4">
                <h3>
                    <template v-if="birthYear">
                        <a @click="expanded = !expanded" class="tw-text-allabolag-600 tw-text-sm"
                           :href="linkToConnection !== '' ? linkToConnection: linkTo">
                            {{ currentBoardMember.namn }} {{birthYear? '(f&nbsp;' + birthYear + ')': ''}}
                        </a>
                    </template>
                    <span v-else class="tw-text-allabolag-600 tw-text-sm">
                        {{ currentBoardMember.namn }}
                    </span>
                </h3>
                <i v-if="shouldShow"
                   class="tablet:tw-hidden fas tw-text-gray-500 tw-text-2xl tw-cursor-pointer"
                   :class="expanded? 'fa-chevron-up':'fa-chevron-down'">
                </i>
            </div>
            <p class="tablet:tw-hidden tw-my-1 tw-text-sm">
                    <span v-for="(assignment, index) in currentBoardMember.assignments">
                        {{assignment.description}}<span v-if="index !== currentBoardMember.assignments.length-1">,&nbsp;</span>
                    </span>
                <br>
                <span v-if="shouldShow">
                    Aktiva uppdrag: <span class="tw-font-semibold">{{activeMissionsCount}} stycken</span><br>
                    Tidigare uppdrag: <span class="tw-font-semibold">{{historicalMissionCount}} stycken</span>
                </span>
            </p>
            <div class="tw-hidden tablet:tw-flex tw-my-1 tw-text-sm tw-w-1/4 tw-pl-5 tw-flex-wrap">
                <span v-for="(assignment, index) in currentBoardMember.assignments">
                    {{assignment.description}}<span class="tw-hidden tablet:tw-inline"
                                                    v-if="index !== currentBoardMember.assignments.length-1">,&nbsp;</span>
                    <span class="tablet:tw-hidden" v-if="index !== currentBoardMember.assignments.length-1"><br></span>
                </span>
            </div>
            <div class="tw-hidden tablet:tw-flex tw-my-1 tw-text-sm tw-w-1/4 tw-pl-5 tw-flex-wrap">
                <span v-if="shouldShow" class="tw-font-semibold">{{activeMissionsCount}} stycken</span>
            </div>
            <div class="tw-hidden tablet:tw-flex tw-my-1 tw-text-sm tw-w-1/4 tw-pl-5 tw-flex-wrap tw-justify-between">
                <span v-if="shouldShow" class="tw-font-semibold">{{historicalMissionCount}} stycken</span>
                <i v-if="shouldShow"
                   class="fas tw-text-gray-500 tw-text-2xl tw-cursor-pointer"
                   :class="expanded? 'fa-chevron-up':'fa-chevron-down'">
                </i>
            </div>
        </div>

        <div class="tw-mx-2 tablet:tw-mx-16" v-if="shouldShow && expanded"
             :class="{'tw-bg-gray-50': expanded}">
            <div class="tw-mt-8">
                <h3 class="tw-pl-2 tw-py-2">
                    Aktiva uppdrag (visar {{computedActiveMissionCount}} av {{activeMissionsCount}})
                </h3>
                <executive-person-items :companies="executivesActive"
                                        :is-historical="false"></executive-person-items>
                <div class="tw-flex tw-justify-center tw-my-3">
                    <a class="tw-text-sm tw-text-blue-450 tw-font-normal hover:tw-underline hover:tw-text-blue-500"
                       :href="linkTo">Visa alla aktiva uppdrag</a>
                </div>
                <div v-if="historicalMissionCount > 0" class="tw-mt-6">
                    <div class="tw-relative tw-flex">
                        <h3 class="tw-pl-2 tw-py-2">
                            Tidigare uppdrag (visar {{computedHistoricalMissionCount}} av {{historicalMissionCount}})
                            <span class="tw-absolute tw--mt-3">
                                <plus-indicator></plus-indicator>
                            </span>
                        </h3>
                    </div>
                    <div v-if="hasPlus" v-for="(items, index) in filteredHistoricalItems">
                        <executive-person-items :companies="items"
                                                :is-historical="true"></executive-person-items>
                    </div>
                    <div v-if="hasPlus" class="tw-flex tw-justify-center tw-my-3">
                        <a class="tw-text-sm tw-text-blue-450 tw-font-normal hover:tw-underline hover:tw-text-blue-500"
                           :href="linkToConnection + '#historiska'">
                            Visa alla historiska uppdrag
                        </a>
                    </div>
                    <executive-person-history-plus-ad :has-plus="hasPlus"></executive-person-history-plus-ad>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ExecutivePersonHistoryPlusAd from "./EexecutivePersonHistoryPlusAd.vue";
    import ExecutivePersonItems from "./ExecutivePersonItems.vue"

    export default {
        name: "ExecutivePerson",

        components: {
            ExecutivePersonHistoryPlusAd,
            ExecutivePersonItems,
        },
        props: [
            'internalId',
            'birthYear',
            'activeMissionsCount',
            'historicalMissionCount',
            'companies',
            'companiesHistorical',
            'hasPlus',
            'linkTo',
            'linkToConnection',
            'index',
            'indexLast',
            'currentBoardMember',
        ],
        data: function () {
            return {
                filteredHistoricalItems: [],
                expanded: false,
                isLast: false,
                executivesActive: this.companies[0],
            }
        },
        mounted() {
            this.isLast = this.index === (this.indexLast - 1);
            this.filteredHistoricalItems = this.companiesHistorical.filter(
                (companiesHistoricalItem) => {
                    if (typeof companiesHistoricalItem[0] !== 'undefined') {
                        return companiesHistoricalItem[0].id === this.internalId;
                    } else {
                        return false;
                    }
                }
            );
        },
        computed: {
            computedActiveMissionCount() {
                return this.activeMissionsCount < 4 ? this.activeMissionsCount : 3
            },
            computedHistoricalMissionCount() {
                return this.historicalMissionCount < 4 ? this.historicalMissionCount : 3;
            },
            shouldShow() {
                return this.currentBoardMember !== undefined && this.currentBoardMember.type !== 'company' && this.birthYear !== undefined;
            }
        },
    }
</script>
