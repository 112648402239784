<template>
    <div class="spinner"
         :style="{
            width: width,
            height: height,
            borderBottomWidth: borderWidth,
            'border-top-width': borderWidth,
            'border-left-width': borderWidth,
            'border-right-width': borderWidth,
    }"></div>
</template>
<style scoped>
    .spinner {
        border-radius: 50%;
        border-top: 16px solid #A5B83E;
        border-right: 16px solid #D1DB9E;
        border-bottom: 16px solid #A5B83E;
        border-left: 16px solid #D1DB9E;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
<script>
    export default {
        props: {
            width: {
                default: '120px'
            },
            height: {
                default: '120px'
            },
            borderWidth: {
                default: '16px'
            },
        }
    }
</script>
