<template>
    <dropdown-wrapper class="tw-py-4" dropdown-classes="tw-w-26">
        <template v-slot:menu-button>
            <MenuButton v-slot="{ open }"
                        class="tw-appearance-none tw-h-full tw-text-white tw-hidden desktop:tw-block ml-1 tw-text-sm">
                Fler val
                <span class="far fa-angle-down tw-mx-1 tw-transform tw-duration-200 tw-text-xs"
                      :class="{'tw-rotate-180': open}"></span>
            </MenuButton>
        </template>
        <template v-slot:menu-items>
            <MenuItem v-slot="{ active }" v-for="menuItem in menuItems">
                <a role="button" :class="[active && 'tw-text-allabolag-700 tw-underline']"
                   class="tw-block tw-w-full tw-px-4 tw-py-2" :href="menuItem.link">
                    {{ menuItem.text }}
                </a>
            </MenuItem>
        </template>
    </dropdown-wrapper>
</template>
<script>
import {MenuButton, MenuItem} from "@headlessui/vue";
import DropdownWrapper from "./DropdownWrapper.vue";

export default {
    components: {
        DropdownWrapper,
        MenuButton,
        MenuItem,
    },
    props: {
        menuItems: Array,
        buttonText: String,
    },
}
</script>
