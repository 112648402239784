<template>
    <div :class="[doNotDisplay? 'tw-hidden' : '']" style="min-height: 185px">
        <div class="strossle-widget"></div>
    </div>
</template>

<script>
export default {
    name: "strossle",
    props: {
        doNotDisplay: {
            default: false
        },
        mobileId: {
            type: String,
            default: '59d09613-7396-4671-a759-89d3e8e7ce3c'
        },
        desktopId: {
            type: String,
            default: '6e0c6802-13fe-478c-a35c-96c426bd97b8'
        }
    },
    mounted() {
        window.strossleIdDesktopOrLaptop = this.mobileId;
        if (screen && screen.width > 900) {
            window.strossleIdDesktopOrLaptop = this.desktopId;
        }
        if (this.doNotDisplay === true){
            window.strossleIdDesktopOrLaptop = 'c8cded3d-fa04-4e4c-9965-389590cbe350';
        }
        window.strossle = window.strossle || function () {
            (strossle.q = strossle.q || []).push(arguments)
        };
        window.strossle(window.strossleIdDesktopOrLaptop, '.strossle-widget');
    }
}
</script>

<style scoped>

</style>
