<template>
    <div v-if="!hasPlus" class="tw-relative tw-z-1">
        <div class="tw-absolute tw-z-1 tw-top-0 tw-bottom-0 tw-left-0 tw-right-0">
            <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full">
                <button @click="goToPlus()"
                        class="tw-btn tw-btn-purple tw-w-11/12 tablet:tw-w-1/3 tw-py-3 tw-rounded-lg tw-font-bold">Köp
                    allabolag plus
                </button>
            </div>
        </div>
        <div class="blur tw-bg-white tw-border-b tw-border-t tw-border-gray-200 tw-p-2 blurDivBoxIE borderIE">
            <div class="tablet:tw-flex tablet:tw-items-center">
                <h3 class="tw-text-allabolag-600 tablet:tw-w-1/4 blurGreenIE">Proff AB</h3>
                <p class="tw-text-sm tablet:tw-ml-4 blurBlackIE">
                    Extern verkställande direktör<span class="tablet:tw-hidden"><br></span>
                    sedan 2019-08-05
                </p>
            </div>
        </div>
        <div class="blur tw-bg-white tw-border-b tw-border-gray-200 tw-p-2 blurDivBoxIE borderIE">
            <div class="tablet:tw-flex tablet:tw-items-center">
                <h3 class="tw-text-allabolag-600 tablet:tw-w-1/4 blurGreenIE">UC AB</h3>
                <p class="tw-text-sm tablet:tw-ml-4 blurBlackIE">
                    Extern verkställande direktör<span class="tablet:tw-hidden"><br></span>
                    sedan 2019-08-05
                </p>
            </div>
        </div>
        <div class="blur tw-bg-white tw-border-b tw-border-gray-200 tw-p-2 blurDivBoxIE borderIE">
            <div class="tablet:tw-flex tablet:tw-items-center">
                <h3 class="tw-text-allabolag-600 tablet:tw-w-1/4 blurGreenIE">Allabolag AB</h3>
                <p class="tw-text-sm tablet:tw-ml-4 blurBlackIE">
                    Extern verkställande direktör<span class="tablet:tw-hidden"><br></span>
                    sedan 2019-08-05
                </p>
            </div>
        </div>
        <div class="blur tw-flex tw-justify-center tw-mt-3 tw-mb-8">
            <a class="tw-text-sm tw-text-blue-450 tw-font-normal hover:tw-underline blurBlueIE" href="#">
                Visa alla historiska uppdrag
            </a>
        </div>
    </div>
</template>

<style scoped>
    .blur {
        filter: blur(5px);
    }

    /** IE11 hacks, visible only in IE11 */
    @media all and (-ms-high-contrast: none) {
        .blurDivBoxIE {
            box-shadow: 0 0 5px 5px rgba(255,255,255,0.8);
            opacity: 0.8;
        }

        .blurGreenIE {
            text-shadow: 0 0 0.6em 0.1em rgba(137, 154, 52, 0.65);
            color: rgba(137, 154, 52, 0.01);
        }

        .blurBlackIE {
            text-shadow: 0 0 0.6em 0.1em rgba(30, 30, 30, 0.45);
            color: rgba(30, 30, 30, 0.01);
        }

        .blurBlueIE {
            text-shadow: 0 0 0.6em 0.1em rgba(0, 180, 230, 0.65);
            color: rgba(0, 180, 230, 0.01);
        }

        .borderIE {
            border: none;
        }
    }
</style>

<script>
    export default {
        name: "ExecutivePersonHistoryPlusAd",

        props: [
            'hasPlus',
        ],
        methods: {
            goToPlus() {
                location.href = '/plus';
            },
        }
    }
</script>
