<template>
    <div
        class="tablet:tw-absolute tablet:tw-bottom-0 tablet:tw-mb-4 tablet:tw-flex tablet:tw-w-full tablet:tw-flex-1 tablet:tw-pr-24"
    >
        <CompanySearch
            name="orgNr"
            placeholder="Sök företagsnamn eller org.nr"
            :on-click-option="onClick"
            @new-search="clearInputs"
            :use-name="true"
            outer-container-class="tw-flex-1"
        >
        </CompanySearch>
        <form method="post" :action="checkoutUrl" ref="form">
            <input type="hidden" name="_token" v-model="csrf" />
            <input name="orgNr" type="hidden" v-model="orgNr" />
            <input name="useTics" type="hidden" value="false" />
            <input
                name="productType"
                type="hidden"
                :value="props.productType"
            />
            <input name="companyType" type="hidden" value="" />
            <input name="SNIcode" type="hidden" value="" />
            <input name="SNIindustry" type="hidden" value="" />
            <input name="industryCategory" type="hidden" value="" />
            <input name="industrySubCategory" type="hidden" value="" />
            <button
                class="tw-mt-2 tw-rounded tw-border-2 tw-bg-allabolag-500 tw-px-4 tw-py-2 tw-text-lg tw-font-bold tw-text-white tw-transition tw-duration-200 hover:tw-bg-allabolag-600 disabled:tw-bg-allabolag-500 disabled:tw-text-gray-300 tablet:tw-ml-2 tablet:tw-mt-1"
                :disabled="disableButton"
                type="submit"
            >
                Sök företag
            </button>
        </form>
    </div>
</template>

<script setup>
import CompanySearch from '../CompanySearch.vue'
import { ref } from 'vue'

const props = defineProps({
    route: {
        type: String,
        required: true,
    },
    productType: {
        type: String,
        required: true,
    },
})
const csrf = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')

const disableButton = ref(true)
const orgNr = ref('')
const checkoutUrl = props.route

const onClick = (company) => {
    disableButton.value = false
    orgNr.value = company.orgnr
}

const clearInputs = () => {
    disableButton.value = true
    orgNr.value = ''
}
</script>
