<template>
    <div>
        <label :for="'compare-' + orgnrWithoutDash">
            <input v-model="checkedCompareCompanies"
                   @click="toggleCompare(orgnrWithoutDash, name)"
                   :name="'compare-' + orgnrWithoutDash"
                   :id="'compare-' + orgnrWithoutDash"
                   type="checkbox"/>
            <span class="checkboxicon"></span> Jämför
        </label>
    </div>
</template>

<script>
    import {EventBus} from "../common/eventBus";

    export default {
        name: "CompareCompaniesCheckbox",
        props: {
            orgnr: String,
            name: String,
            selectedForCompare: {
                type: Array,
                required: false,
                default: []
            }
        },
        data: function () {
            return {
                checkedCompareCompanies: false,
            }
        },
        computed: {
            orgnrWithoutDash: function() {
                return this.orgnr.replace('-', '');
            }
        },
        created() {
            this.checkedCompareCompanies = this.selectedForCompare.find(company => company.orgnr === this.orgnrWithoutDash) instanceof Object;
            EventBus.on('removed-compare-company', this.removeFromCompare);
            EventBus.on('add-to-compare-company-call', this.addToCompare);
            EventBus.on('max-companies-reached', this.maxReached);
        },
        methods: {
            maxReached(event) {
                if (event.orgnr === this.orgnrWithoutDash) {
                    setTimeout(this.maxTimeout, 10);
                }
            },
            maxTimeout() {
                this.checkedCompareCompanies = false;
            },
            toggleCompare(orgnr, name) {
                if (this.checkedCompareCompanies) {
                    EventBus.emit('removed-compare-company-call', {'orgnr': orgnr.replace('-', '')});
                } else {
                    EventBus.emit('add-to-compare-company-call', {'orgnr': orgnr, 'name': name});
                }
            },
            removeFromCompare(event) {
                if (this.orgnrWithoutDash === event.orgnr.replace('-', '')) {
                    this.checkedCompareCompanies = false;
                }
            },
            addToCompare(event) {
                if (this.orgnrWithoutDash === event.orgnr.replace('-', '')) {
                    this.checkedCompareCompanies = true;
                }
            }
        }
    }
</script>
