<template>
    <div>
        <div class="tw-absolute">
            <div
                class="tw-fixed tw-inset-0 tw-z-50 tw-cursor-pointer tw-bg-black tw-opacity-50"
                :class="{ 'tw-hidden': !overlay }"
                @click="overlay = false"
            ></div>
            <div
                class="h-fixed tw-fixed tw-left-0 tw-right-0 tw-top-0 tw-z-50 tw-mx-auto tw-my-4 tw-max-w-2xl tw-overflow-auto tw-rounded-lg tw-bg-white tw-text-gray-900 tw-shadow-xl tablet:tw-bottom-0 tablet:tw-my-auto"
                :class="{ 'tw-hidden': !overlay }"
            >
                <div
                    class="tw-flex tw-items-start tw-justify-between tw-border-b tw-border-gray-100 tw-p-4"
                >
                    <h2>Köp UC Företagsvärdering Premium</h2>
                    <button @click="overlay = false" class="tw-text-xl">
                        <i class="far fa-times"></i>
                    </button>
                </div>
                <div class="tw-p-4">
                    <p class="tw-mb-4 tw-text-sm">
                        Denna värdering passar när du behöver ha ett indikativt
                        aktievärde på ett aktiebolag. Det kan vara i situationer
                        där du vill följa bolagets värdeutveckling, ta in nya
                        aktieägare eller på andra sätt presentera värdet för
                        styrelse och andra intressenter.
                    </p>
                    <p class="tw-text-sm">
                        Värderingen bygger på vad bolaget och branschen har
                        presterat historiskt. Värderingen är en maskinell analys
                        vilket innebär att den är opåverkad av analytikers
                        antaganden och beaktar inte icke officiell information
                        som t ex preliminära bokslut eller bolagets egen
                        framtidsprognos.
                    </p>
                    <div class="tw-mb-5 tw-mt-3 tw-block tw-text-sm">
                        <a
                            class="tw-font-normal"
                            target="_blank"
                            href="/Assets/pdf/demo/UC_Foretagsvardering_Premium.pdf"
                            >Se exempel (pdf)</a
                        >
                    </div>
                    <p class="tw-mb-3 tw-font-bold">Dessa bolag kan värderas</p>
                    <ul class="itemlist tw-mb-4">
                        <li class="tw-mb-3">
                            Aktiebolag med minst 3 registrerade bokslut
                        </li>
                        <li class="tw-mb-3">
                            Senast inlämnat bokslut får inte vara äldre än 24
                            månader
                        </li>
                        <li class="tw-mb-3">
                            Aktivt företag med positivt justerat eget kapital
                            för alla år
                        </li>
                        <li class="tw-mb-3">
                            Viss omsättning för senaste tre åren
                        </li>
                        <li class="tw-mb-3">Riskklassat av UC</li>
                        <li class="tw-mb-3">
                            Entydig bokslutshistorik för att möjliggöra
                            framtidsprognoser
                        </li>
                    </ul>
                    <div class="tw-block tablet:tw-flex">
                        <div
                            class="tw-mb-4 tw-rounded tw-border tw-border-gray-200 tw-p-4 tablet:tw-mb-0 tablet:tw-mr-2 tablet:tw-w-1/2"
                        >
                            <div class="tw-mb-4">
                                <i class="far fa-briefcase tw-text-lg"></i>
                                <span class="tw-ml-1 tw-text-lg"
                                    >Bolagsvärdering</span
                                >
                            </div>
                            <div class="tw-mb-1">
                                <span class="tw-text-2xl tw-font-bold"
                                    >{{ companyprice }} kr</span
                                >
                                <span
                                    class="tw-text-sm tw-font-bold tw-text-gray-500"
                                    >exkl. moms</span
                                >
                            </div>
                            <slot name="companyvaluationbtn"></slot>
                        </div>
                        <div
                            class="tw-rounded tw-border tw-border-gray-200 tw-p-4 tablet:tw-ml-2 tablet:tw-w-1/2"
                        >
                            <div class="tw-mb-4">
                                <i class="far fa-building tw-text-lg"></i>
                                <span class="tw-ml-1 tw-text-lg"
                                    >Koncernvärdering</span
                                >
                            </div>
                            <div class="tw-mb-1">
                                <span class="tw-text-2xl tw-font-bold"
                                    >{{ groupprice }} kr</span
                                >
                                <span
                                    class="tw-text-sm tw-font-bold tw-text-gray-500"
                                    >exkl. moms</span
                                >
                            </div>
                            <slot name="groupvaluationbtn"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="tw-mt-3">
            <strong>Behöver du en komplett företagsvärdering?</strong>
            <button
                @click="overlay = true"
                class="btn-link tw-mt-1"
                data-modal-id="valuation-full"
            >
                Klicka här för att läsa mer
            </button>
        </p>
    </div>
</template>
<style scoped>
.itemlist li {
    min-height: 21px;
    font-size: 14px;
    padding-left: 2.25rem;
    background: rgba(0, 0, 0, 0) url('/resources/assets/img/icons/check-mark.svg')
        no-repeat scroll left top;
}
.h-fixed {
    height: auto;
    max-height: 100%;
}
@media screen and (min-width: 768px) {
    .h-fixed {
        height: 665px;
    }
}
</style>
<script>
import { onUnmounted } from 'vue'

export default {
    props: {
        groupprice: String,
        companyprice: String
    },
    data() {
        return {
            overlay: false,
        }
    },
    watch: {
        overlay(value) {
            return value
                ? document
                      .querySelector('body')
                      .classList.add('tw-overflow-hidden')
                : document
                      .querySelector('body')
                      .classList.remove('tw-overflow-hidden')
        },
    },
    created() {
        const escapeHandler = (e) => {
            if (e.key === 'Escape' && this.overlay) {
                this.overlay = false
            }
        }
        document.addEventListener('keydown', escapeHandler)
        onUnmounted(() => {
            document.removeEventListener('keydown', escapeHandler)
        })
    },
}
</script>
