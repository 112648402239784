import * as Sentry from "@sentry/vue";

import './bootstrap';
import CompanyEvents from './components/CompanyEvents.vue'
import Executives from './components/executives/Executives.vue'
import BeneficialOwners from './components/executives/BeneficialOwners.vue'
import BisgrafSelectYear from './components/bisgraf/BisgrafSelectYear.vue'
import CompareCompaniesCheckbox from './components/compare-companies/CompareCompaniesCheckbox.vue'
import PlusIndicator from './components/common/PlusIndicator.vue'
import StrossleComponent from './components/strossle/strossle.vue'

import {createApp, defineAsyncComponent} from 'vue';
import{ NdsButton } from '@nds/vue';
window.createApp = createApp;
window.companyevents = CompanyEvents;
window.executives = Executives;
window.beneficialOwners = BeneficialOwners;
window.bisgrafselect = BisgrafSelectYear;
window.comparecompaniescheckbox = CompareCompaniesCheckbox;
window.plusindicator = PlusIndicator;
window.strosslecomponent = StrossleComponent;
const app = createApp({});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


import ButtonModal from './components/common/ButtonModal.vue'
app.component('button-modal', ButtonModal);
import DropdownMenu from './components/navbar/DropdownMenu.vue'
app.component('dropdown-menu', DropdownMenu);
import DropdownSearch from './components/navbar/DropdownSearch.vue'
app.component('dropdown-search', DropdownSearch);
import DropdownCompareCompanies from './components/navbar/DropdownCompareCompanies.vue'
app.component('dropdown-compare-companies', DropdownCompareCompanies);

app.component('company-events', window.companyevents);
app.component('search', defineAsyncComponent(() => import('./components/search/Search.vue')));
import CompanyValuationFull from './components/CompanyValuationFull.vue'
app.component('company-valuation-full', CompanyValuationFull);
app.component('export-results', defineAsyncComponent(() => import('./components/search/ExportResults.vue')));
app.component('plus-indicator', window.plusindicator);
app.component('executives', window.executives);
app.component('beneficial-owners', window.beneficialOwners);
app.component('bisgraf-select-year', window.bisgrafselect);
app.component('compare-companies', defineAsyncComponent(() => import('./components/compare-companies/CompareCompanies.vue')));
app.component('compare-companies-checkbox', window.comparecompaniescheckbox);
app.component('compare-companies-ad', defineAsyncComponent(() => import('./components/compare-companies/CompareCompaniesAd.vue')));
app.component('compare-companies-max-reached-modal', defineAsyncComponent(() => import('./components/compare-companies/CompareCompaniesMaxReachedModal.vue')));
app.component('strossle', window.strosslecomponent);
app.component('plus-slideup', defineAsyncComponent(() => import('./components/plus/PlusSlideUp.vue')));
import PlusOptions from './components/plus/PlusOptions.vue'
app.component('plus-options', PlusOptions);
app.component('smec-product-widget', defineAsyncComponent(() => import('./components/plus/SmecProductWidget.vue')));

app.component('faq', defineAsyncComponent(() => import('./components/goava/Faq.vue')));
app.component('goava-inline-form', defineAsyncComponent(() => import('./components/goava/GoavaContactForm.vue')));
app.component('company-monitor', defineAsyncComponent(() => import('./components/CompanyMonitor.vue')));
app.component('better-business-product', defineAsyncComponent(() => import('./components/betterbusiness/BetterBusinessProduct.vue')));
app.component('better-business-modal-form', defineAsyncComponent(() => import('./components/betterbusiness/BetterBusinessModalForm.vue')));
app.component('better-business-inline-form', defineAsyncComponent(() => import('./components/betterbusiness/BetterBusinessInlineForm.vue')));
app.component('advertising-card', defineAsyncComponent(() => import('./components/advertising/AdvertisingCard.vue')));
app.component('inline-contact-form', defineAsyncComponent(() => import('./components/product/InlineContactForm.vue')));
app.component('company-valuation-search', defineAsyncComponent(() => import('./components/CompanyValuationSearch.vue')))
app.component('valuation-modal', defineAsyncComponent(() => import('./components/ValuationModal.vue')))
import CompanyActionsProductBoxSearchCTA from './components/account/CompanyActionsProductBoxSearchCTA.vue'
app.component('company-actions-product-box-search-cta', CompanyActionsProductBoxSearchCTA);
app.component('nds-card', defineAsyncComponent(() => import('./components/nds-components/NdsCard.vue')))
app.component('nds-radio', defineAsyncComponent(() => import('./components/nds-components/NdsRadio.vue')))
app.component('nds-button', NdsButton)

app.component('bolagshandlingar', defineAsyncComponent(() => import('./components/account/Bolagshandlingar.vue')));
app.component('kvitton', defineAsyncComponent(() => import('./components/account/Kvitton.vue')));
app.component('abonnemang', defineAsyncComponent(() => import('./components/account/Abonnemang.vue')));
app.component('abonnemang-edit', defineAsyncComponent(() => import('./components/account/AbonnemangEdit.vue')));
app.component('abonnemang-end-modal', defineAsyncComponent(() => import('./components/account/AbonnemangEndModal.vue')));
app.component('personuppgifter', defineAsyncComponent(() => import('./components/account/Personuppgifter.vue')));

app.component('annual-report-downloader', defineAsyncComponent(() => import('./components/AnnualReportDownloader.vue')));

app.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

Sentry.init({
    app,
    dsn: "https://3ae1dfc40ba212d635dae91e0ba0f5c7@sentry.uc.se/288",
    integrations: [
        Sentry.browserTracingIntegration({
            beforeStartSpan: (context) => {
                return {
                    ...context,
                    name: window.sentrydata.transactionpath,
                };
            }
        }),
        Sentry.thirdPartyErrorFilterIntegration({
            filterKeys: ["allabolagse-sentry-appkey"],
            behaviour: "drop-error-if-exclusively-contains-third-party-frames"
        })
    ],
    environment: window.sentrydata.environment,

    // Performance Monitoring
    tracesSampleRate: window.location.hostname === 'www.allabolag.se' ? 0.01 : 1.0, //  Capture 1% in production, but 100% in all other environments

    // Vue specific settings
    trackComponents: true
});

if (window.sentrydata.user) {
    Sentry.setUser({
        id: window.sentrydata.user,
        username: window.sentrydata.username
    });
}

app.mount('#app');

window.vueLoadedIds = $(document.getElementById('#app')).find('div.vue-container').map(function () { return $(this).attr('id') }).toArray();
